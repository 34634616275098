<template>
  <div class="address-page">
    <van-form @submit="onSubmit">
      <van-field
        v-model="detail.Account"
        name="Account"
        :label="PAGELANG.收款账户"
        :placeholder="PAGELANG.请输入收款账户"
        :rules="[{ required: true, message: PAGELANG.收款账户不能为空 }]"
      />
      <van-field
        v-model="detail.BankName"
        name="BankName"
        :label="PAGELANG.开户行"
        :placeholder="PAGELANG.请输入开户行"
        :rules="[{ required: true, message: PAGELANG.开户行不能为空 }]"
      />
      <van-field
        v-model="detail.AccountName"
        name="AccountName"
        :label="PAGELANG.开户人姓名"
        :placeholder="PAGELANG.请输入开户人姓名"
        :rules="[{ required: true, message: PAGELANG.开户人姓名不能为空 }]"
      />

      <div class="add-btn-block">
        <van-button
          type="primary"
          block
          color="var(--van-card-price-color)"
          native-type="submit"
          >{{ SYSLANG.save }}</van-button
        >
      </div>
    </van-form>

    <van-button
      v-if="accountid > 0"
      plain
      square
      block
      type="primary"
      class="btn-delete"
      @click="onDelete"
      >{{ SYSLANG.delete }}</van-button
    >
  </div>
</template>

<script>
import {
  closeToast,
  showConfirmDialog,
  showLoadingToast,
  showToast,
} from "vant";
import { getCookie } from "../util";
export default {
  data() {
    return {
      accountid: 0,
      detail: {},
    };
  },
  beforeCreate() {
    if (!getCookie("agentloginid")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentloginid")) {
      return;
    }

    this.SYSLANG = this.LANG.system;
    this.PAGELANG = this.LANG.pages.income;

    if (this.$route.query.accountid) {
      this.accountid = this.$route.query.accountid;
      if (!isNaN(this.accountid) && this.accountid > 0) {
        this.getAccount();
      }
    }
  },
  methods: {
    getAccount() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.axios
        .get(this.actions.income_account_detail + this.accountid)
        .then(({ data }) => {
          closeToast();
          let { code, msg, detail } = data;
          console.log(code, msg, detail);
          if (code != "SUCCESS") {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          } else if (detail) {
            this.detail = detail;
          } else {
            this.accountid = 0;
          }
        })
        .catch((error) => {
          closeToast();
          console.log("views.IncomeAccountEdit.getAccount.error", error);
          this.getAccount();
        });
    },
    onSubmit(postdata) {
      console.log("submit", postdata);
      postdata.accountid = this.accountid;

      showLoadingToast({
        message: this.SYSLANG.正在保存,
        duration: 0,
        forbidClick: true,
      });
      this.axios
        .post(this.actions.income_account_save, postdata)
        .then(({ data }) => {
          closeToast();
          let { code, msg } = data;
          showToast({
            message:
              code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
            forbidClick: true,
            onClose: () => {
              if (code == "SUCCESS") {
                this.$router.back();
              }
            },
          });
        });
    },
    onDelete() {
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.您确定要删除该收款账户吗,
        confirmButtonText: this.SYSLANG.queding,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          showLoadingToast({
            message: this.SYSLANG.正在删除,
            duration: 0,
            forbidClick: true,
          });
          this.axios
            .get(this.actions.income_account_delete + this.accountid)
            .then(({ data }) => {
              closeToast();
              let { code, msg } = data;
              showToast({
                message:
                  code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
                forbidClick: true,
                onClose: () => {
                  if (code == "SUCCESS") {
                    this.$router.back();
                  }
                },
              });
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style src="../assets/css/address.css" scoped></style>
<style scoped>
.address-page {
  padding: 16px 0 var(--van-submit-bar-height) 0;
  --van-cell-vertical-padding: 14px;
}

.address-page >>> .van-cell::after {
  left: calc(var(--van-padding-md) + var(--van-field-label-width));
}

.btn-delete {
  --van-button-radius: 0;
  --van-button-primary-border-color: #fff;
  --van-button-primary-background: #fe295f;
  --van-button-default-height: var(--van-submit-bar-button-height);
  --van-button-normal-font-size: var(--van-submit-bar-font-size);
  margin-top: 16px;
}
</style>
